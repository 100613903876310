import { Button } from '@mui/material';
import './App.css';
import mulher from './img/mulher.png';
import logo from './img/logop.png';

function App() {
  return (
    <div className="bg-[url('./img/bg.jpg')]">
      <div className="bg-blue-800 h-[70px]">
        <img
          className="ml-5 h-full py-2"
          src={ logo }
          alt="logo"
        />
      </div>
      <div className="h-[calc(100vh-70px)] md:flex md:justify-center">
        <div className="flex flex-col items-center">
          <p
            className="md:text-[45px] text-[30px] text-center md:mx-32 mx-10 font-bold
          lg:mb-20 mb-14"
          >
            <span
              className="bg-yellow-300 text-red-500"
            >
              [BÔNUS GRÁTIS NO PRIMEIRO DEPÓSITO]
            </span>
            <br />
            Quer conhecer a plataforma do momento?🚀🚀
          </p>
          <Button
            className="w-[300px] md:h-16"
            variant="contained"
            onClick={ () => window.open('https://www.lurkerpg777.com/register?id=73687017&currency=BRL&type=2') }
          >
            Clique Aqui
          </Button>
        </div>
      </div>
      <img
        className="sm:h-[380px] fixed bottom-0 left-1/2 transform -translate-x-1/2"
        src={ mulher }
        alt="mulher"
      />
    </div>
  );
}

export default App;
